import React, { useEffect, useState } from 'react'
import { Button, Divider, HStack, VStack } from '@/XelaReact'
import { LoadingOverlay, SelectItem, SimpleGrid } from '@mantine/core'
import { useDispatch } from 'react-redux'
import { useForm } from '@inertiajs/react'
import { updateShowAddQuickLeadModal } from '@/Store/leadSlice'
import XSelect from '@/Mantine/XSelect'
import XTextInput from '@/Mantine/XTextInput'
import useLocation from '@/Hooks/useLocation'
import useFlash from '@/Hooks/useFlash'
import useMeta from '@/Hooks/useMeta'
import axios from 'axios'
import useAuth from '@/Hooks/useAuth'
import XPhoneInput from '@/Mantine/XPhoneInput'
import XSelectWithRole from '@/Mantine/XSelectWithRole'
import User = App.Models.User

const CreateQuickVisitorLead = () => {
  const auth = useAuth()
  const meta = useMeta()
  const flash = useFlash()
  const dispatch = useDispatch()
  const location = useLocation()
  const [loading, setLoading] = useState(false)
  const [countries, setCountries] = useState<SelectItem[]>([])

  const { data, setData, post, errors, reset } = useForm<{
    lead_source: string
    sub_lead_source: string
    given_name: string
    surname: string
    phone: string
    email: string
    country: string
    country_code: string
    country_calling_code: string
    travel_country: string
    travel_country_code: string
    policy_type: string
    branch_id: string
    lead_manager_id: string
  }>({
    lead_source: 'Walk In',
    sub_lead_source: '',
    given_name: '',
    surname: '',
    phone: '',
    email: '',
    country: 'India',
    country_code: 'IN',
    country_calling_code: '+91',
    travel_country: '',
    travel_country_code: '',
    policy_type: 'Student',
    branch_id: auth.branch.id.toString(),
    lead_manager_id: '',
  })

  useEffect(() => {
    if (flash.action === 'insurance-lead:created') {
      const payload = flash.payload as {
        insurance_lead_id: number | string
      } | null

      if (payload) {
        const leadManagerId = data.lead_manager_id
        dispatch(updateShowAddQuickLeadModal(false))
        reset()

        if (
          auth.role.permissions.includes('insurance_lead_module_all_leads') ||
          ((auth.role.permissions.includes('insurance_lead_module_manage_lead') ||
            auth.role.permissions.includes('insurance_lead_module_branch_leads')) &&
            leadManagerId === auth.user.id.toString())
        ) {
          window.open((route('insurance-leads.id', { id: payload.insurance_lead_id }) as unknown) as string, '_self')
        }
      }
    }
  }, [flash.timestamp])

  useEffect(() => {
    if (location.country_code) {
      setData((prevData) => ({
        ...prevData,
        country: location.country_name,
        country_code: location.country_code,
        country_calling_code: location.country_calling_code,
      }))
    }
  }, [location])

  useEffect(() => {
    axios
      .post<Record<string, string>>('/select/meta-options', {
        entity_name: 'countries',
        column_name: 'country',
        with_key: true,
      })
      .then((response) => {
        const countryList = Object.keys(response.data).map((key) => {
          return {
            value: key,
            label: response.data[key],
          }
        })

        setCountries(countryList)
      })
      .catch((error) => {
        console.error('Error fetching countries:', error)
      })
  }, [])

  const [counsellorData, setCounsellorData] = useState(
    meta.branchesUsers[auth.branch.id].users.map((counsellor) => {
      let isCounsellorEnabled = false

      if (
        counsellor.role.permissions.includes('insurance_lead_module_manage_lead') ||
        counsellor.role.permissions.includes('insurance_lead_module_branch_leads') ||
        counsellor.role.permissions.includes('insurance_lead_module_all_leads')
      ) {
        isCounsellorEnabled = true
      }

      return {
        value: counsellor.id.toString(),
        label: counsellor.name,
        group: counsellor.branch.branch_name,
        disabled: !isCounsellorEnabled,
        role: counsellor.role.role_name,
      }
    })
  )

  useEffect(() => {
    setData((prevData) => ({
      ...prevData,
      lead_manager_id: '',
    }))

    if (data.branch_id) {
      setCounsellorData(
        meta.branchesUsers[data.branch_id].users.map((counsellor) => {
          let isCounsellorEnabled = false

          if (
            counsellor.role.permissions.includes('insurance_lead_module_manage_lead') ||
            counsellor.role.permissions.includes('insurance_lead_module_branch_leads') ||
            counsellor.role.permissions.includes('insurance_lead_module_all_leads')
          ) {
            isCounsellorEnabled = true
          }

          return {
            value: counsellor.id.toString(),
            label: counsellor.name,
            group: counsellor.branch.branch_name,
            role: counsellor.role.role_name,
            disabled: !isCounsellorEnabled,
          }
        })
      )
    }
  }, [data.branch_id])

  useEffect(() => {
    const branchLeadPermission = auth.role.permissions.includes('insurance_lead_module_branch_leads')
    const allPermission = auth.role.permissions.includes('insurance_lead_module_all_leads')
    const leadPermission = auth.role.permissions.includes('insurance_lead_module_manage_lead')

    if (data.branch_id) {
      const users = meta.branchesUsers[data.branch_id]?.users || []

      const filterAndMapCounsellors = (users: User[]) =>
        users.map((counsellor) => {
          const isCounsellorEnabled = [
            'insurance_lead_module_manage_lead',
            'insurance_lead_module_branch_leads',
            'insurance_lead_module_all_leads',
          ].some((permission) => counsellor.role.permissions.includes(permission))

          return {
            value: counsellor.id.toString(),
            label: counsellor.name,
            group: counsellor.branch.branch_name,
            role: counsellor.role.role_name,
            disabled: !isCounsellorEnabled,
          }
        })

      if (allPermission) {
        setCounsellorData(filterAndMapCounsellors(users))
      } else if (branchLeadPermission && data.branch_id === auth.user.branch_id.toString()) {
        const branchUsers = users.filter((user) => user.branch.branch_name === auth.user.branch.branch_name)
        setCounsellorData(filterAndMapCounsellors(branchUsers))
      } else if (leadPermission) {
        const filteredCounsellors = meta.counsellors.filter((counsellor) => counsellor.id === auth.user.id)
        setCounsellorData(filterAndMapCounsellors(filteredCounsellors))
      } else {
        const filteredCounsellors = meta.counsellors.filter((counsellor) => counsellor.id === auth.user.id)
        setCounsellorData(filterAndMapCounsellors(filteredCounsellors))
      }
    }
  }, [data.branch_id])

  return (
    <VStack
      spacing="12px"
      style={{
        height: '100%',
        position: 'relative',
      }}
    >
      <LoadingOverlay visible={loading} overlayBlur={2} loaderProps={{ color: 'blueX' }} />
      <VStack
        style={{
          flex: '1 1 0',
          height: 0,
          overflowY: 'auto',
          padding: '2px',
        }}
      >
        <SimpleGrid style={{ width: '100%', alignItems: 'flex-start' }} cols={2} spacing={12} verticalSpacing={12}>
          <HStack>
            <XTextInput
              data-autofocus
              required
              label={'Given Name'}
              error={errors.given_name}
              value={data.given_name}
              onChange={(event) => {
                setData((prevData) => ({
                  ...prevData,
                  given_name: event.target.value,
                }))
              }}
            />
          </HStack>
          <HStack>
            <XTextInput
              label={'Surname'}
              error={errors.surname}
              value={data.surname}
              onChange={(event) => {
                setData((prevData) => ({
                  ...prevData,
                  surname: event.target.value,
                }))
              }}
            />
          </HStack>
          <HStack>
            <XPhoneInput
              error={errors.phone}
              dialCode={data.country_calling_code}
              countryCode={data.country_code}
              initialValue={data.phone}
              onChange={(number, dialCode, countryCode, country) => {
                setData((prevData) => ({
                  ...prevData,
                  phone: number,
                  country_calling_code: dialCode,
                  country_code: countryCode,
                  country: country,
                }))
              }}
            ></XPhoneInput>
          </HStack>
          <HStack>
            <XTextInput
              label={'Email'}
              error={errors.email}
              value={data.email}
              onChange={(event) => {
                setData((prevData) => ({
                  ...prevData,
                  email: event.target.value,
                }))
              }}
            />
          </HStack>
          <HStack>
            <XSelect
              searchable
              label={'Travel Country'}
              error={errors.travel_country}
              value={data.travel_country_code}
              data={countries}
              onChange={(value) => {
                if (value) {
                  setData((prevData) => ({
                    ...prevData,
                    travel_country_code: value,
                    travel_country: countries.find((country) => country.value === value)?.label as string,
                  }))
                }
              }}
            />
          </HStack>
          <HStack>
            <XSelect
              searchable
              label={'Policy For'}
              error={errors.policy_type}
              value={data.policy_type}
              data={['Student', 'Tourist']}
              onChange={(value) => {
                if (value) {
                  setData((prevData) => ({
                    ...prevData,
                    policy_type: value,
                  }))
                }
              }}
            />
          </HStack>
          <HStack>
            <XSelect
              searchable
              required
              label="Select Branch"
              value={data.branch_id}
              error={errors.branch_id}
              data={auth.user.branches.map((branch) => {
                return {
                  value: branch.id.toString(),
                  label: branch.branch_name,
                }
              })}
              onChange={(value) => {
                if (value) {
                  setData((prevData) => ({
                    ...prevData,
                    branch_id: value,
                  }))
                }
              }}
            />
          </HStack>
          <HStack>
            <XSelectWithRole
              searchable
              required
              label="Select Lead Manager"
              value={data.lead_manager_id}
              error={errors.lead_manager_id}
              data={counsellorData}
              onChange={(value) => {
                if (value) {
                  setData((prevData) => ({
                    ...prevData,
                    lead_manager_id: value,
                  }))
                }
              }}
            />
          </HStack>
          <HStack>
            <XSelect
              searchable
              required
              label={'Lead Source'}
              error={errors.lead_source}
              value={data.lead_source}
              onInit={(callback) => {
                axios
                  .post('/select/meta-options', {
                    entity_name: 'insurance_leads',
                    column_name: 'lead_source',
                  })
                  .then((response) => {
                    callback(response.data)
                  })
                  .catch(() => {
                    callback([])
                  })
              }}
              onChange={(value) => {
                if (value) {
                  setData((prevData) => ({
                    ...prevData,
                    lead_source: value,
                  }))
                }
              }}
            />
          </HStack>
          <HStack>
            <XTextInput
              label={'Sub Lead Source'}
              error={errors.sub_lead_source}
              value={data.sub_lead_source}
              onChange={(event) => {
                setData((prevData) => ({
                  ...prevData,
                  sub_lead_source: event.target.value,
                }))
              }}
            />
          </HStack>
        </SimpleGrid>
      </VStack>
      <HStack>
        <Divider variant={'dotted'}></Divider>
      </HStack>
      <HStack spacing={'12px'} justifyContent="flex-end">
        <Button
          label={'Cancel'}
          variant={'secondary'}
          onClick={() => {
            dispatch(updateShowAddQuickLeadModal(false))
            reset()
          }}
        ></Button>
        <Button
          label={'Create'}
          onClick={() => {
            setLoading(true)
            post(route('insurance-leads.quick-create'), {
              preserveState: true,
              preserveScroll: true,
              onFinish: () => {
                setLoading(false)
              },
            })
          }}
        ></Button>
      </HStack>
    </VStack>
  )
}

export default CreateQuickVisitorLead
