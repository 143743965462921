import React, { useEffect, useState } from 'react'
import { Button, Divider, HStack, TabItem, Tabs, Typography, VStack, XelaColor } from '@/XelaReact'
import { Checkbox, LoadingOverlay, SelectItem } from '@mantine/core'
import { useDispatch } from 'react-redux'
import { router, useForm } from '@inertiajs/react'
import { updateShowAddQuickLeadModal } from '@/Store/leadSlice'
import XSelect from '@/Mantine/XSelect'
import XTextInput from '@/Mantine/XTextInput'
import useLocation from '@/Hooks/useLocation'
import useFlash from '@/Hooks/useFlash'
import useMeta from '@/Hooks/useMeta'
import axios from 'axios'
import useAuth from '@/Hooks/useAuth'
import XPhoneInput from '@/Mantine/XPhoneInput'
import { EntitySectionData } from '@/Types/EntitySubTypes'
import { getOrderOfFieldByLabel, getSelectedOfFieldByLabel } from '@/helpers'
import XDatePicker from '@/Mantine/XDatePicker'
import moment from 'moment'
import XNumberInput from '@/Mantine/XNumberInput'
import COUNTRY_FORMATS from '@/Constants/CountryFormats'
import XSelectWithRole from '@/Mantine/XSelectWithRole'
import User = App.Models.User

const CreateQuickIeltsLead = () => {
  const auth = useAuth()
  const meta = useMeta()
  const flash = useFlash()
  const dispatch = useDispatch()
  const location = useLocation()
  const [loading, setLoading] = useState(false)
  const [fieldsData] = useState<EntitySectionData>(meta.entityForms['coaching_lead_form'])
  const [categoryData, setCategoryData] = useState<Record<string, boolean>>({})
  const [languageTab, setLanguageTab] = React.useState('')
  const [countries, setCountries] = useState<SelectItem[]>([])

  const { data, setData, post, errors, reset } = useForm<{
    lead_source: string
    sub_lead_source: string
    given_name: string
    surname: string
    phone: string
    email: string
    country: string
    country_code: string
    country_calling_code: string
    branch_id: string
    lead_manager_id: string
    alternate_phone: string
    alternate_country: string
    alternate_country_code: string
    alternate_country_calling_code: string
    father_name: string
    mother_name: string
    date_of_birth: string
    gender: string
    marital_status: string
    visa_type: string
    address: string
    city: string
    state: string
    pin_code: string
    application_email: string
    application_password: string
    exam_portal_login_id: string
    exam_portal_login_password: string
    passport_number: string
    passport_nationality: string
    passport_issue_date: string
    passport_expiry_date: string
    is_visa_rejected_in_past: boolean
    visa_rejection_one_country: string
    visa_rejection_one_year: number | ''
    visa_rejection_one_reason: string
    work_experience_one_company_name: string
    work_experience_one_job_title: string
    work_experience_one_is_still_working: boolean
    work_experience_one_start_date: string
    work_experience_one_end_date: string
    work_experience_salary_one_description: string
    language_ielts_academic_test_date: string | null
    language_ielts_academic_overall_score: number | null | ''
    language_ielts_academic_listening_score: number | null | ''
    language_ielts_academic_reading_score: number | null | ''
    language_ielts_academic_writing_score: number | null | ''
    language_ielts_academic_speaking_score: number | null | ''
    language_ielts_general_test_date: string | null
    language_ielts_general_overall_score: number | null | ''
    language_ielts_general_listening_score: number | null | ''
    language_ielts_general_reading_score: number | null | ''
    language_ielts_general_writing_score: number | null | ''
    language_ielts_general_speaking_score: number | null | ''
    language_pte_academic_test_date: string | null
    language_pte_academic_overall_score: number | null | ''
    language_pte_academic_listening_score: number | null | ''
    language_pte_academic_reading_score: number | null | ''
    language_pte_academic_speaking_score: number | null | ''
    language_pte_academic_writing_score: number | null | ''
    language_pte_general_test_date: string | null
    language_pte_general_overall_score: number | null | ''
    language_pte_general_listening_score: number | null | ''
    language_pte_general_reading_score: number | null | ''
    language_pte_general_speaking_score: number | null | ''
    language_pte_general_writing_score: number | null | ''
    language_toefl_academic_test_date: string | null
    language_toefl_academic_overall_score: number | null | ''
    language_toefl_academic_reading_score: number | null | ''
    language_toefl_academic_listening_score: number | null | ''
    language_toefl_academic_speaking_score: number | null | ''
    language_toefl_academic_writing_score: number | null | ''
    language_toefl_home_edition_test_date: string | null
    language_toefl_home_edition_overall_score: number | null | ''
    language_toefl_home_edition_reading_score: number | null | ''
    language_toefl_home_edition_listening_score: number | null | ''
    language_toefl_home_edition_speaking_score: number | null | ''
    language_toefl_home_edition_writing_score: number | null | ''
    language_duolingo_test_date: string | null
    language_duolingo_overall_score: number | null | ''
    language_duolingo_literacy_score: number | null | ''
    language_duolingo_conversation_score: number | null | ''
    language_duolingo_comprehension_score: number | null | ''
    language_duolingo_production_score: number | null | ''
    course_type: string
  }>({
    lead_source: 'Walk In',
    sub_lead_source: '',
    given_name: '',
    surname: '',
    phone: '',
    email: '',
    country: 'India',
    country_code: 'IN',
    country_calling_code: '+91',
    branch_id: auth.branch.id.toString(),
    lead_manager_id: '',
    alternate_phone: '',
    alternate_country: 'India',
    alternate_country_code: 'IN',
    alternate_country_calling_code: '+91',
    father_name: '',
    mother_name: '',
    date_of_birth: '',
    gender: 'Male',
    marital_status: 'Not Married',
    visa_type: '',
    address: '',
    city: '',
    state: '',
    pin_code: '',
    application_email: '',
    application_password: '',
    exam_portal_login_id: '',
    exam_portal_login_password: '',
    passport_number: '',
    passport_nationality: '',
    passport_issue_date: '',
    passport_expiry_date: '',
    is_visa_rejected_in_past: false,
    visa_rejection_one_country: '',
    visa_rejection_one_year: '',
    visa_rejection_one_reason: '',
    work_experience_one_company_name: '',
    work_experience_one_job_title: '',
    work_experience_one_is_still_working: false,
    work_experience_one_start_date: '',
    work_experience_one_end_date: '',
    work_experience_salary_one_description: '',
    language_ielts_academic_test_date: null,
    language_ielts_academic_overall_score: null,
    language_ielts_academic_listening_score: null,
    language_ielts_academic_reading_score: null,
    language_ielts_academic_writing_score: null,
    language_ielts_academic_speaking_score: null,
    language_ielts_general_test_date: null,
    language_ielts_general_overall_score: null,
    language_ielts_general_listening_score: null,
    language_ielts_general_reading_score: null,
    language_ielts_general_writing_score: null,
    language_ielts_general_speaking_score: null,
    language_pte_academic_test_date: null,
    language_pte_academic_overall_score: null,
    language_pte_academic_listening_score: null,
    language_pte_academic_reading_score: null,
    language_pte_academic_speaking_score: null,
    language_pte_academic_writing_score: null,
    language_pte_general_test_date: null,
    language_pte_general_overall_score: null,
    language_pte_general_listening_score: null,
    language_pte_general_reading_score: null,
    language_pte_general_speaking_score: null,
    language_pte_general_writing_score: null,
    language_toefl_academic_test_date: null,
    language_toefl_academic_overall_score: null,
    language_toefl_academic_reading_score: null,
    language_toefl_academic_listening_score: null,
    language_toefl_academic_speaking_score: null,
    language_toefl_academic_writing_score: null,
    language_toefl_home_edition_test_date: null,
    language_toefl_home_edition_overall_score: null,
    language_toefl_home_edition_reading_score: null,
    language_toefl_home_edition_listening_score: null,
    language_toefl_home_edition_speaking_score: null,
    language_toefl_home_edition_writing_score: null,
    language_duolingo_test_date: null,
    language_duolingo_overall_score: null,
    language_duolingo_literacy_score: null,
    language_duolingo_conversation_score: null,
    language_duolingo_comprehension_score: null,
    language_duolingo_production_score: null,
    course_type: '',
  })

  useEffect(() => {
    if (fieldsData) {
      const categoryData: Record<string, boolean> = {}
      Object.entries(fieldsData).forEach(([category, formItems]) => {
        categoryData[category] = formItems.some((formItem) => formItem.selected)
      })
      setCategoryData(categoryData)
    }
  }, [fieldsData])

  useEffect(() => {
    if (flash.action === 'ielts-lead:created') {
      const payload = flash.payload as {
        ielts_lead_id: number | string
      } | null

      if (payload) {
        const leadManagerId = data.lead_manager_id
        dispatch(updateShowAddQuickLeadModal(false))
        reset()

        if (
          auth.role.permissions.includes('ielts_lead_module_all_leads') ||
          ((auth.role.permissions.includes('ielts_lead_module_manage_lead') ||
            auth.role.permissions.includes('ielts_lead_module_branch_leads') ||
            auth.role.permissions.includes('ielts_lead_module_all_leads')) &&
            leadManagerId === auth.user.id.toString())
        ) {
          router.visit(route('ielts-leads.id', { id: payload.ielts_lead_id }))
        }
      }
    }
  }, [flash.timestamp])

  useEffect(() => {
    if (location.country_code) {
      setData((prevData) => ({
        ...prevData,
        country: location.country_name,
        country_code: location.country_code,
        country_calling_code: location.country_calling_code,
      }))
    }
  }, [location])

  const [counsellorData, setCounsellorData] = useState(
    meta.branchesUsers[auth.branch.id].users.map((counsellor) => {
      let isCounsellorEnabled = false
      if (
        counsellor.role.permissions.includes('ielts_lead_module_manage_lead') ||
        counsellor.role.permissions.includes('ielts_lead_module_branch_leads') ||
        counsellor.role.permissions.includes('ielts_lead_module_all_leads')
      ) {
        isCounsellorEnabled = true
      }

      return {
        value: counsellor.id.toString(),
        label: counsellor.name,
        group: counsellor.branch.branch_name,
        disabled: !isCounsellorEnabled,
      }
    })
  )

  useEffect(() => {
    setData((prevData) => ({
      ...prevData,
      lead_manager_id: '',
    }))

    if (data.branch_id) {
      setCounsellorData(
        meta.branchesUsers[data.branch_id].users.map((counsellor) => {
          let isCounsellorEnabled = false

          if (counsellor.role.permissions.includes('ielts_lead_module_manage_lead')) {
            isCounsellorEnabled = true
          }
          return {
            value: counsellor.id.toString(),
            label: counsellor.name,
            group: counsellor.branch.branch_name,
            role: counsellor.role.role_name,
            disabled: !isCounsellorEnabled,
          }
        })
      )
    }
  }, [data.branch_id])

  useEffect(() => {
    const branchLeadPermission = auth.role.permissions.includes('ielts_lead_module_branch_leads')
    const allPermission = auth.role.permissions.includes('ielts_lead_module_all_leads')
    const leadPermission = auth.role.permissions.includes('ielts_lead_module_manage_lead')

    if (data.branch_id) {
      const users = meta.branchesUsers[data.branch_id]?.users || []

      const filterAndMapCounsellors = (users: User[]) =>
        users.map((counsellor) => {
          const isCounsellorEnabled = [
            'ielts_lead_module_manage_lead',
            'ielts_lead_module_branch_leads',
            'ielts_lead_module_all_leads',
          ].some((permission) => counsellor.role.permissions.includes(permission))

          return {
            value: counsellor.id.toString(),
            label: counsellor.name,
            group: counsellor.branch.branch_name,
            role: counsellor.role.role_name,
            disabled: !isCounsellorEnabled,
          }
        })

      if (allPermission) {
        setCounsellorData(filterAndMapCounsellors(users))
      } else if (branchLeadPermission && data.branch_id === auth.user.branch_id.toString()) {
        const branchUsers = users.filter((user) => user.branch.branch_name === auth.user.branch.branch_name)
        setCounsellorData(filterAndMapCounsellors(branchUsers))
      } else if (leadPermission) {
        const filteredCounsellors = meta.counsellors.filter((counsellor) => counsellor.id === auth.user.id)
        setCounsellorData(filterAndMapCounsellors(filteredCounsellors))
      } else {
        const filteredCounsellors = meta.counsellors.filter((counsellor) => counsellor.id === auth.user.id)
        setCounsellorData(filterAndMapCounsellors(filteredCounsellors))
      }
    }
  }, [data.branch_id])

  const languageTabArrangement = [
    {
      label: 'IELTS Academic',
      value: 'IELTS Academic',
      fieldName: 'language_ielts_academic',
      order: getOrderOfFieldByLabel(fieldsData, 'language_test_details', 'language_ielts_academic'),
    },
    {
      label: 'IELTS General',
      value: 'IELTS General',
      fieldName: 'language_ielts_general',
      order: getOrderOfFieldByLabel(fieldsData, 'language_test_details', 'language_ielts_general'),
    },
    {
      label: 'PTE Academic',
      value: 'PTE Academic',
      fieldName: 'language_pte_academic',
      order: getOrderOfFieldByLabel(fieldsData, 'language_test_details', 'language_pte_academic'),
    },
    {
      label: 'PTE General',
      value: 'PTE General',
      fieldName: 'language_pte_general',
      order: getOrderOfFieldByLabel(fieldsData, 'language_test_details', 'language_pte_general'),
    },
    {
      label: 'TOEFL Academic',
      value: 'TOEFL Academic',
      fieldName: 'language_toefl_academic',
      order: getOrderOfFieldByLabel(fieldsData, 'language_test_details', 'language_toefl_academic'),
    },
    {
      label: 'TOEFL Home Edition',
      value: 'TOEFL Home Edition',
      fieldName: 'language_toefl_home_edition',
      order: getOrderOfFieldByLabel(fieldsData, 'language_test_details', 'language_toefl_home_edition'),
    },
    {
      label: 'Duolingo',
      value: 'Duolingo',
      fieldName: 'language_duolingo',
      order: getOrderOfFieldByLabel(fieldsData, 'language_test_details', 'language_duolingo'),
    },
  ]

  languageTabArrangement.sort((a, b) => a.order - b.order)

  useEffect(() => {
    if (languageTabArrangement.length > 0) {
      setLanguageTab(languageTabArrangement[0].label)
    }
  }, [])

  useEffect(() => {
    axios
      .post<Record<string, string>>('/select/meta-options', {
        entity_name: 'countries',
        column_name: 'country',
        with_key: true,
      })
      .then((response) => {
        const countryList = Object.keys(response.data).map((key) => {
          return {
            value: key,
            label: response.data[key],
          }
        })

        setCountries(countryList)
      })
      .catch((error) => {
        console.error('Error fetching countries:', error)
      })
  }, [])

  return (
    <VStack
      spacing="12px"
      style={{
        height: '100%',
        position: 'relative',
      }}
    >
      <LoadingOverlay visible={loading} overlayBlur={2} loaderProps={{ color: 'blueX' }} />
      <VStack
        className={'pr-2 overflow-y-auto'}
        spacing={'32px'}
        style={{
          flex: '1 1 0',
          height: 0,
        }}
      >
        <VStack spacing={'12px'}>
          <Typography color={XelaColor.Blue3} variant={'body-small-bold'}>
            Personal Details
          </Typography>
          <div className="grid grid-cols-2 w-full gap-4 items-start p-[1px]">
            {getSelectedOfFieldByLabel(fieldsData, 'personal_details', 'given_name') && (
              <HStack style={{ order: getOrderOfFieldByLabel(fieldsData, 'personal_details', 'given_name') }}>
                <XTextInput
                  data-autofocus
                  required
                  label={'Given Name'}
                  error={errors.given_name}
                  value={data.given_name}
                  onChange={(event) => {
                    setData((prevData) => ({
                      ...prevData,
                      given_name: event.target.value,
                    }))
                  }}
                />
              </HStack>
            )}
            {getSelectedOfFieldByLabel(fieldsData, 'personal_details', 'surname') && (
              <HStack style={{ order: getOrderOfFieldByLabel(fieldsData, 'personal_details', 'surname') }}>
                <XTextInput
                  label={'Surname'}
                  error={errors.surname}
                  value={data.surname}
                  onChange={(event) => {
                    setData((prevData) => ({
                      ...prevData,
                      surname: event.target.value,
                    }))
                  }}
                />
              </HStack>
            )}
            {getSelectedOfFieldByLabel(fieldsData, 'personal_details', 'phone') && (
              <HStack style={{ order: getOrderOfFieldByLabel(fieldsData, 'personal_details', 'phone') }}>
                <XPhoneInput
                  error={errors.phone}
                  dialCode={data.country_calling_code}
                  countryCode={data.country_code}
                  initialValue={data.phone}
                  onChange={(number, dialCode, countryCode, country) => {
                    setData((prevData) => ({
                      ...prevData,
                      phone: number,
                      country_calling_code: dialCode,
                      country_code: countryCode,
                      country: country,
                    }))
                  }}
                ></XPhoneInput>
              </HStack>
            )}
            <HStack style={{ order: getOrderOfFieldByLabel(fieldsData, 'personal_details', 'email') }}>
              <XTextInput
                label={'Email'}
                error={errors.email}
                value={data.email}
                onChange={(event) => {
                  setData((prevData) => ({
                    ...prevData,
                    email: event.target.value,
                  }))
                }}
              />
            </HStack>
            <HStack style={{ order: getOrderOfFieldByLabel(fieldsData, 'personal_details', 'branch_id') }}>
              <XSelect
                searchable
                required
                label="Select Branch"
                value={data.branch_id}
                error={errors.branch_id}
                data={auth.user.branches.map((branch) => {
                  return {
                    value: branch.id.toString(),
                    label: branch.branch_name,
                  }
                })}
                onChange={(value) => {
                  if (value) {
                    setData((prevData) => ({
                      ...prevData,
                      branch_id: value,
                    }))
                  }
                }}
              />
            </HStack>
            <HStack style={{ order: getOrderOfFieldByLabel(fieldsData, 'personal_details', 'lead_manager_id') }}>
              <XSelectWithRole
                searchable
                required
                label="Select Lead Manager"
                value={data.lead_manager_id}
                error={errors.lead_manager_id}
                data={counsellorData}
                onChange={(value) => {
                  if (value) {
                    setData((prevData) => ({
                      ...prevData,
                      lead_manager_id: value,
                    }))
                  }
                }}
              />
            </HStack>
            <HStack style={{ order: getOrderOfFieldByLabel(fieldsData, 'personal_details', 'lead_source') }}>
              <XSelect
                searchable
                required
                label={'Lead Source'}
                error={errors.lead_source}
                value={data.lead_source}
                onInit={(callback) => {
                  axios
                    .post('/select/meta-options', {
                      entity_name: 'ielts_leads',
                      column_name: 'lead_source',
                    })
                    .then((response) => {
                      callback(response.data)
                    })
                    .catch(() => {
                      callback([])
                    })
                }}
                onChange={(value) => {
                  if (value) {
                    setData((prevData) => ({
                      ...prevData,
                      lead_source: value,
                    }))
                  }
                }}
              />
            </HStack>
            {getSelectedOfFieldByLabel(fieldsData, 'personal_details', 'sub_lead_source') && (
              <HStack style={{ order: getOrderOfFieldByLabel(fieldsData, 'personal_details', 'sub_lead_source') }}>
                <XTextInput
                  label={'Sub Lead Source'}
                  error={errors.sub_lead_source}
                  value={data.sub_lead_source}
                  onChange={(event) => {
                    setData((prevData) => ({
                      ...prevData,
                      sub_lead_source: event.target.value,
                    }))
                  }}
                />
              </HStack>
            )}
            {getSelectedOfFieldByLabel(fieldsData, 'personal_details', 'gender') && (
              <HStack style={{ order: getOrderOfFieldByLabel(fieldsData, 'personal_details', 'gender') }}>
                <XSelect
                  searchable
                  label={'Gender'}
                  error={errors.gender}
                  value={data.gender}
                  data={['Male', 'Female', 'Gender Fluid/Non Binary']}
                  onChange={(value) => {
                    if (value) {
                      setData((prevData) => ({
                        ...prevData,
                        gender: value,
                      }))
                    }
                  }}
                />
              </HStack>
            )}
            {getSelectedOfFieldByLabel(fieldsData, 'personal_details', 'date_of_birth') && (
              <HStack style={{ order: getOrderOfFieldByLabel(fieldsData, 'personal_details', 'date_of_birth') }}>
                <XDatePicker
                  clearable
                  dateParser={(dateString) => {
                    return moment(dateString, 'DD/MM/YYYY').toDate()
                  }}
                  value={data.date_of_birth ? moment(data.date_of_birth as string, 'YYYY-MM-DD').toDate() : null}
                  width="100%"
                  placeholder="DD/MM/YYYY"
                  valueFormat={'DD/MM/YYYY'}
                  maxDate={new Date()}
                  label={'Date of Birth'}
                  onChange={(value) => {
                    if (value) {
                      setData((prevData) => ({
                        ...prevData,
                        date_of_birth: moment(value).format('YYYY-MM-DD'),
                      }))
                    } else {
                      setData((prevData) => ({
                        ...prevData,
                        date_of_birth: '',
                      }))
                    }
                  }}
                  error={errors.date_of_birth}
                />
              </HStack>
            )}
            {getSelectedOfFieldByLabel(fieldsData, 'personal_details', 'marital_status') && (
              <HStack style={{ order: getOrderOfFieldByLabel(fieldsData, 'personal_details', 'marital_status') }}>
                <XSelect
                  searchable
                  label={'Marital Status'}
                  error={errors.marital_status}
                  value={data.marital_status}
                  data={['Married', 'Not Married']}
                  onChange={(value) => {
                    if (value) {
                      setData((prevData) => ({
                        ...prevData,
                        marital_status: value,
                      }))
                    }
                  }}
                />
              </HStack>
            )}

            {getSelectedOfFieldByLabel(fieldsData, 'personal_details', 'alternate_phone') && (
              <HStack style={{ order: getOrderOfFieldByLabel(fieldsData, 'personal_details', 'alternate_phone') }}>
                <XPhoneInput
                  label={'Alternate Phone'}
                  error={errors.alternate_phone}
                  dialCode={data.country_calling_code}
                  countryCode={data.country_code}
                  initialValue={data.alternate_phone}
                  onChange={(number, dialCode, countryCode, country) => {
                    setData((prevData) => ({
                      ...prevData,
                      alternate_phone: number,
                      country_calling_code: dialCode,
                      country_code: countryCode,
                      country: country,
                    }))
                  }}
                  checkMobileRemoteValidation={true}
                ></XPhoneInput>
              </HStack>
            )}
            {getSelectedOfFieldByLabel(fieldsData, 'personal_details', 'father_name') && (
              <HStack style={{ order: getOrderOfFieldByLabel(fieldsData, 'personal_details', 'father_name') }}>
                <XTextInput
                  data-autofocus
                  label={'Father Name'}
                  error={errors.father_name}
                  value={data.father_name}
                  onChange={(event) => {
                    setData((prevData) => ({
                      ...prevData,
                      father_name: event.target.value,
                    }))
                  }}
                />
              </HStack>
            )}
            {getSelectedOfFieldByLabel(fieldsData, 'personal_details', 'mother_name') && (
              <HStack style={{ order: getOrderOfFieldByLabel(fieldsData, 'personal_details', 'mother_name') }}>
                <XTextInput
                  data-autofocus
                  label={'Mother Name'}
                  error={errors.mother_name}
                  value={data.mother_name}
                  onChange={(event) => {
                    setData((prevData) => ({
                      ...prevData,
                      mother_name: event.target.value,
                    }))
                  }}
                />
              </HStack>
            )}
            {getSelectedOfFieldByLabel(fieldsData, 'personal_details', 'course_type') && (
              <HStack style={{ order: getOrderOfFieldByLabel(fieldsData, 'personal_details', 'course_type') }}>
                <XSelect
                  searchable
                  label={'Course Type'}
                  error={errors.course_type}
                  value={data.course_type}
                  onInit={(callback) => {
                    axios
                      .post('/select/meta-options', {
                        entity_name: 'ielts_leads',
                        column_name: 'course_type',
                      })
                      .then((response) => {
                        callback(response.data)
                      })
                      .catch(() => {
                        callback([])
                      })
                  }}
                  onChange={(value) => {
                    if (value) {
                      setData((prevData) => ({
                        ...prevData,
                        course_type: value,
                      }))
                    }
                  }}
                />
              </HStack>
            )}
            {getSelectedOfFieldByLabel(fieldsData, 'personal_details', 'country') && (
              <HStack style={{ order: getOrderOfFieldByLabel(fieldsData, 'personal_details', 'country') }}>
                <XSelect
                  searchable
                  label={'Country'}
                  error={errors.country}
                  value={data.country_code}
                  data={countries}
                  onChange={(value) => {
                    if (value) {
                      setData((prevData) => ({
                        ...prevData,
                        country_code: value,
                        country: countries.find((country) => country.value === value)?.label as string,
                      }))
                    }
                  }}
                />
              </HStack>
            )}
            {getSelectedOfFieldByLabel(fieldsData, 'personal_details', 'address') && (
              <HStack style={{ order: getOrderOfFieldByLabel(fieldsData, 'personal_details', 'address') }}>
                <XTextInput
                  label={'Address'}
                  error={errors.address}
                  value={data.address}
                  onChange={(event) => {
                    setData((prevData) => ({
                      ...prevData,
                      address: event.target.value,
                    }))
                  }}
                />
              </HStack>
            )}
            {getSelectedOfFieldByLabel(fieldsData, 'personal_details', 'state') && (
              <HStack style={{ order: getOrderOfFieldByLabel(fieldsData, 'personal_details', 'state') }}>
                <XTextInput
                  label={'State'}
                  error={errors.state}
                  value={data.state}
                  onChange={(event) => {
                    setData((prevData) => ({
                      ...prevData,
                      state: event.target.value,
                    }))
                  }}
                />
              </HStack>
            )}
            {getSelectedOfFieldByLabel(fieldsData, 'personal_details', 'city') && (
              <HStack style={{ order: getOrderOfFieldByLabel(fieldsData, 'personal_details', 'city') }}>
                <XTextInput
                  label={'City'}
                  error={errors.city}
                  value={data.city}
                  onChange={(event) => {
                    setData((prevData) => ({
                      ...prevData,
                      city: event.target.value,
                    }))
                  }}
                />
              </HStack>
            )}
            {getSelectedOfFieldByLabel(fieldsData, 'personal_details', 'pincode') && (
              <HStack style={{ order: getOrderOfFieldByLabel(fieldsData, 'personal_details', 'pincode') }}>
                <XTextInput
                  label={'Pincode'}
                  error={errors.pin_code}
                  value={data.pin_code}
                  onChange={(event) => {
                    setData((prevData) => ({
                      ...prevData,
                      pin_code: event.target.value,
                    }))
                  }}
                />
              </HStack>
            )}
          </div>
        </VStack>
        {categoryData['application_details'] && (
          <VStack spacing={'12px'}>
            <Divider variant={'dotted'} />
            <Typography color={XelaColor.Blue3} variant={'body-small-bold'}>
              Application Details
            </Typography>

            <div className="grid grid-cols-2 w-full gap-4 items-start p-[1px]">
              {getSelectedOfFieldByLabel(fieldsData, 'application_details', 'application_email') && (
                <HStack
                  style={{ order: getOrderOfFieldByLabel(fieldsData, 'application_details', 'application_email') }}
                >
                  <XTextInput
                    data-autofocus
                    label={'Application Email'}
                    error={errors.application_email}
                    value={data.application_email}
                    onChange={(event) => {
                      setData((prevData) => ({
                        ...prevData,
                        application_email: event.target.value,
                      }))
                    }}
                  />
                </HStack>
              )}
              {getSelectedOfFieldByLabel(fieldsData, 'application_details', 'application_password') && (
                <HStack
                  style={{ order: getOrderOfFieldByLabel(fieldsData, 'application_details', 'application_password') }}
                >
                  <XTextInput
                    data-autofocus
                    label={'Application Password'}
                    error={errors.application_password}
                    value={data.application_password}
                    onChange={(event) => {
                      setData((prevData) => ({
                        ...prevData,
                        application_password: event.target.value,
                      }))
                    }}
                  />
                </HStack>
              )}
              {getSelectedOfFieldByLabel(fieldsData, 'application_details', 'exam_portal_login_id') && (
                <HStack
                  style={{ order: getOrderOfFieldByLabel(fieldsData, 'application_details', 'exam_portal_login_id') }}
                >
                  <XTextInput
                    data-autofocus
                    label={'Exam Portal Login ID'}
                    error={errors.exam_portal_login_id}
                    value={data.exam_portal_login_id}
                    onChange={(event) => {
                      setData((prevData) => ({
                        ...prevData,
                        exam_portal_login_id: event.target.value,
                      }))
                    }}
                  />
                </HStack>
              )}
              {getSelectedOfFieldByLabel(fieldsData, 'application_details', 'exam_portal_login_password') && (
                <HStack
                  style={{
                    order: getOrderOfFieldByLabel(fieldsData, 'application_details', 'exam_portal_login_password'),
                  }}
                >
                  <XTextInput
                    data-autofocus
                    label={'Exam Portal Login Password'}
                    error={errors.exam_portal_login_password}
                    value={data.exam_portal_login_password}
                    onChange={(event) => {
                      setData((prevData) => ({
                        ...prevData,
                        exam_portal_login_password: event.target.value,
                      }))
                    }}
                  />
                </HStack>
              )}
            </div>
          </VStack>
        )}
        {categoryData['passport_details'] && (
          <VStack spacing={'12px'}>
            <Divider variant={'dotted'} />
            <Typography color={XelaColor.Blue3} variant={'body-small-bold'}>
              Passport Details
            </Typography>

            <div className="grid grid-cols-2 w-full gap-4 items-start p-[1px]">
              {getSelectedOfFieldByLabel(fieldsData, 'passport_details', 'passport_number') && (
                <HStack
                  style={{
                    order: getOrderOfFieldByLabel(fieldsData, 'passport_details', 'passport_number'),
                  }}
                >
                  <XTextInput
                    data-autofocus
                    label={'Passport Number'}
                    error={errors.passport_number}
                    value={data.passport_number}
                    onChange={(event) => {
                      setData((prevData) => ({
                        ...prevData,
                        passport_number: event.target.value,
                      }))
                    }}
                  />
                </HStack>
              )}
              {getSelectedOfFieldByLabel(fieldsData, 'passport_details', 'passport_nationality') && (
                <HStack
                  style={{
                    order: getOrderOfFieldByLabel(fieldsData, 'passport_details', 'passport_nationality'),
                  }}
                >
                  <XTextInput
                    label={'Passport Nationality'}
                    error={errors.passport_nationality}
                    value={data.passport_nationality}
                    onChange={(event) => {
                      setData((prevData) => ({
                        ...prevData,
                        passport_nationality: event.target.value,
                      }))
                    }}
                  />
                </HStack>
              )}
              {getSelectedOfFieldByLabel(fieldsData, 'passport_details', 'passport_expiry_date') && (
                <HStack
                  style={{
                    order: getOrderOfFieldByLabel(fieldsData, 'passport_details', 'passport_expiry_date'),
                  }}
                >
                  <XDatePicker
                    clearable
                    dateParser={(dateString) => {
                      return moment(dateString, 'DD/MM/YYYY').toDate()
                    }}
                    value={
                      data.passport_expiry_date
                        ? moment(data.passport_expiry_date as string, 'YYYY-MM-DD').toDate()
                        : null
                    }
                    width="100%"
                    placeholder="DD/MM/YYYY"
                    valueFormat={'DD/MM/YYYY'}
                    label={'Passport Expiry Date'}
                    onChange={(value) => {
                      if (value) {
                        setData((prevData) => ({
                          ...prevData,
                          passport_expiry_date: moment(value).format('YYYY-MM-DD'),
                        }))
                      } else {
                        setData((prevData) => ({
                          ...prevData,
                          passport_expiry_date: '',
                        }))
                      }
                    }}
                    error={errors.passport_expiry_date}
                  />
                </HStack>
              )}
              {getSelectedOfFieldByLabel(fieldsData, 'passport_details', 'passport_issue_date') && (
                <HStack
                  style={{
                    order: getOrderOfFieldByLabel(fieldsData, 'passport_details', 'passport_issue_date'),
                  }}
                >
                  <XDatePicker
                    clearable
                    dateParser={(dateString) => {
                      return moment(dateString, 'DD/MM/YYYY').toDate()
                    }}
                    value={
                      data.passport_issue_date
                        ? moment(data.passport_issue_date as string, 'YYYY-MM-DD').toDate()
                        : null
                    }
                    width="100%"
                    placeholder="DD/MM/YYYY"
                    valueFormat={'DD/MM/YYYY'}
                    label={'Passport Issue Date'}
                    onChange={(value) => {
                      if (value) {
                        setData((prevData) => ({
                          ...prevData,
                          passport_issue_date: moment(value).format('YYYY-MM-DD'),
                        }))
                      } else {
                        setData((prevData) => ({
                          ...prevData,
                          passport_issue_date: '',
                        }))
                      }
                    }}
                    error={errors.passport_issue_date}
                  />
                </HStack>
              )}
            </div>
          </VStack>
        )}
        {getSelectedOfFieldByLabel(fieldsData, 'visa_details', 'is_visa_rejected_in_past') && (
          <VStack spacing={'12px'}>
            <Divider variant={'dotted'} />
            <Typography color={XelaColor.Blue3} variant={'body-small-bold'}>
              Visa Details
            </Typography>
            <HStack>
              <Checkbox
                label={'Is Visa Rejected in Past'}
                color={'blueX'}
                checked={data.is_visa_rejected_in_past}
                onChange={(event) => {
                  setData((prevData) => ({
                    ...prevData,
                    is_visa_rejected_in_past: event.target.checked,
                  }))
                }}
              />
            </HStack>
            {data.is_visa_rejected_in_past && (
              <HStack spacing={'12px'}>
                <XSelect
                  searchable
                  data={Object.values(COUNTRY_FORMATS)}
                  label={'Rejection Country'}
                  value={data.visa_rejection_one_country}
                  error={errors.visa_rejection_one_country}
                  onChange={(value) => {
                    if (value) {
                      setData((prevData) => ({
                        ...prevData,
                        visa_rejection_one_country: value,
                      }))
                    }
                  }}
                />
                <XNumberInput
                  type={'number'}
                  label={'Rejection Year'}
                  error={errors.visa_rejection_one_year}
                  defaultValue={data.visa_rejection_one_year}
                  onChange={(value) => {
                    setData((prevData) => ({
                      ...prevData,
                      visa_rejection_one_year: value,
                    }))
                  }}
                />
                <XTextInput
                  label={'Rejection Reason'}
                  error={errors.visa_rejection_one_reason}
                  value={data.visa_rejection_one_reason}
                  onChange={(event) => {
                    setData((prevData) => ({
                      ...prevData,
                      visa_rejection_one_reason: event.target.value,
                    }))
                  }}
                />
              </HStack>
            )}
          </VStack>
        )}
        {getSelectedOfFieldByLabel(fieldsData, 'work_experience', 'work_experience_details') && (
          <VStack spacing={'12px'}>
            <Divider variant={'dotted'} />
            <Typography color={XelaColor.Blue3} variant={'body-small-bold'}>
              Work Experiences
            </Typography>
            <VStack spacing="12px">
              <HStack spacing="12px" alignItems="flex-start">
                <HStack>
                  <XTextInput
                    data-autofocus
                    label={'Company Name'}
                    error={errors.work_experience_one_company_name}
                    value={data.work_experience_one_company_name}
                    onChange={(event) => {
                      setData((prevData) => ({
                        ...prevData,
                        work_experience_one_company_name: event.target.value,
                      }))
                    }}
                  />
                </HStack>
                <HStack>
                  <XTextInput
                    label={'Job Title'}
                    error={errors.work_experience_one_job_title}
                    value={data.work_experience_one_job_title}
                    onChange={(event) => {
                      setData((prevData) => ({
                        ...prevData,
                        work_experience_one_job_title: event.target.value,
                      }))
                    }}
                  />
                </HStack>
              </HStack>
              <VStack spacing="12px" alignItems="flex-start">
                <HStack>
                  <Divider variant={'dotted'} color={XelaColor.Gray11}></Divider>
                </HStack>
                <HStack>
                  <HStack>
                    <Checkbox
                      label={'I am currently working in this role.'}
                      color={'blueX'}
                      checked={data.work_experience_one_is_still_working}
                      onChange={(event) => {
                        setData((prevData) => ({
                          ...prevData,
                          work_experience_one_is_still_working: event.target.checked,
                        }))
                      }}
                    />
                  </HStack>
                </HStack>
              </VStack>
              <HStack spacing="12px" alignItems="flex-start">
                <HStack>
                  <XDatePicker
                    clearable
                    dateParser={(dateString) => {
                      return moment(dateString, 'MM/YYYY').toDate()
                    }}
                    value={
                      data.work_experience_one_start_date
                        ? moment(data.work_experience_one_start_date as string, 'YYYY-MM').toDate()
                        : null
                    }
                    width="100%"
                    placeholder="MM/YYYY"
                    valueFormat={'MM/YYYY'}
                    label={'Start Date'}
                    onChange={(value) => {
                      if (value) {
                        setData((prevData) => ({
                          ...prevData,
                          work_experience_one_start_date: moment(value).format('YYYY-MM'),
                        }))
                      } else {
                        setData((prevData) => ({
                          ...prevData,
                          work_experience_one_start_date: '',
                        }))
                      }
                    }}
                    error={errors.work_experience_one_start_date}
                  />
                </HStack>
                <HStack>
                  <XDatePicker
                    clearable
                    disabled={data.work_experience_one_is_still_working}
                    dateParser={(dateString) => {
                      return moment(dateString, 'MM/YYYY').toDate()
                    }}
                    value={
                      data.work_experience_one_end_date
                        ? moment(data.work_experience_one_end_date as string, 'YYYY-MM').toDate()
                        : null
                    }
                    width="100%"
                    placeholder="MM/YYYY"
                    valueFormat={'MM/YYYY'}
                    label={'End Date'}
                    onChange={(value) => {
                      if (value) {
                        setData((prevData) => ({
                          ...prevData,
                          work_experience_one_end_date: moment(value).format('YYYY-MM'),
                        }))
                      } else {
                        setData((prevData) => ({
                          ...prevData,
                          work_experience_one_end_date: '',
                        }))
                      }
                    }}
                    error={errors.work_experience_one_end_date}
                  />
                </HStack>
              </HStack>
            </VStack>
          </VStack>
        )}
        {categoryData['language_test_details'] && (
          <VStack spacing={'12px'}>
            <Divider variant={'dotted'} />
            <Typography color={XelaColor.Blue3} variant={'body-small-bold'}>
              Language Test Details
            </Typography>

            <Tabs
              className={'whitespace-pre overflow-x-scroll pb-1 custom-scrollbar'}
              style={{
                width: '100%',
                borderBottomWidth: '2px',
                borderBottomStyle: 'solid',
                borderBottomColor: XelaColor.Gray12,
              }}
              items={languageTabArrangement
                .filter((tab) => getSelectedOfFieldByLabel(fieldsData, 'language_test_details', tab.fieldName))
                .map((tab) => new TabItem(tab.label, tab.value))}
              name="language_details_tabs"
              defaultValue={languageTab}
              onChangeHandle={(value) => {
                setLanguageTab(value)
              }}
            ></Tabs>
            {languageTab === 'IELTS Academic' && (
              <VStack spacing="12px" style={{ minHeight: '250px' }}>
                <HStack spacing="12px" alignItems="flex-start">
                  <HStack>
                    <XDatePicker
                      clearable
                      dateParser={(dateString) => {
                        return moment(dateString, 'DD/MM/YYYY').toDate()
                      }}
                      value={
                        data.language_ielts_academic_test_date
                          ? moment(data.language_ielts_academic_test_date as string, 'YYYY-MM-DD').toDate()
                          : null
                      }
                      width="100%"
                      placeholder="DD/MM/YYYY"
                      valueFormat={'DD/MM/YYYY'}
                      label={'Test Date'}
                      onChange={(value) => {
                        if (value) {
                          setData((prevData) => ({
                            ...prevData,
                            language_ielts_academic_test_date: moment(value).format('YYYY-MM-DD'),
                          }))
                        } else {
                          setData((prevData) => ({
                            ...prevData,
                            language_ielts_academic_test_date: '',
                          }))
                        }
                      }}
                      error={errors.language_ielts_academic_test_date}
                    />
                  </HStack>
                  <HStack>
                    <XNumberInput
                      type={'number'}
                      label={'Overall Score'}
                      precision={1}
                      error={errors.language_ielts_academic_overall_score}
                      defaultValue={data.language_ielts_academic_overall_score}
                      onChange={(value) => {
                        if (value !== null) {
                          setData((prevData) => ({
                            ...prevData,
                            language_ielts_academic_overall_score: value,
                          }))
                        }
                      }}
                    />
                  </HStack>
                </HStack>
                <HStack spacing="12px" alignItems="flex-start">
                  <HStack>
                    <XNumberInput
                      type={'number'}
                      label={'Listening Score'}
                      precision={1}
                      error={errors.language_ielts_academic_listening_score}
                      defaultValue={data.language_ielts_academic_listening_score}
                      onChange={(value) => {
                        if (value !== null) {
                          setData((prevData) => ({
                            ...prevData,
                            language_ielts_academic_listening_score: value,
                          }))
                        }
                      }}
                    />
                  </HStack>
                  <HStack>
                    <XNumberInput
                      type={'number'}
                      label={'Reading Score'}
                      precision={1}
                      error={errors.language_ielts_academic_reading_score}
                      defaultValue={data.language_ielts_academic_reading_score}
                      onChange={(value) => {
                        if (value !== null) {
                          setData((prevData) => ({
                            ...prevData,
                            language_ielts_academic_reading_score: value,
                          }))
                        }
                      }}
                    />
                  </HStack>
                </HStack>
                <HStack spacing="12px" alignItems="flex-start">
                  <HStack>
                    <XNumberInput
                      type={'number'}
                      label={'Writing Score'}
                      precision={1}
                      error={errors.language_ielts_academic_writing_score}
                      defaultValue={data.language_ielts_academic_writing_score}
                      onChange={(value) => {
                        if (value !== null) {
                          setData((prevData) => ({
                            ...prevData,
                            language_ielts_academic_writing_score: value,
                          }))
                        }
                      }}
                    />
                  </HStack>
                  <HStack>
                    <XNumberInput
                      type={'number'}
                      label={'Speaking Score'}
                      precision={1}
                      error={errors.language_ielts_academic_speaking_score}
                      defaultValue={data.language_ielts_academic_speaking_score}
                      onChange={(value) => {
                        if (value !== null) {
                          setData((prevData) => ({
                            ...prevData,
                            language_ielts_academic_speaking_score: value,
                          }))
                        }
                      }}
                    />
                  </HStack>
                </HStack>
              </VStack>
            )}
            {languageTab === 'IELTS General' && (
              <VStack spacing="12px" style={{ minHeight: '250px' }}>
                <HStack spacing="12px" alignItems="flex-start">
                  <HStack>
                    <XDatePicker
                      clearable
                      dateParser={(dateString) => {
                        return moment(dateString, 'DD/MM/YYYY').toDate()
                      }}
                      value={
                        data.language_ielts_general_test_date
                          ? moment(data.language_ielts_general_test_date as string, 'YYYY-MM-DD').toDate()
                          : null
                      }
                      width="100%"
                      placeholder="DD/MM/YYYY"
                      valueFormat={'DD/MM/YYYY'}
                      label={'Test Date'}
                      onChange={(value) => {
                        if (value) {
                          setData((prevData) => ({
                            ...prevData,
                            language_ielts_general_test_date: moment(value).format('YYYY-MM-DD'),
                          }))
                        } else {
                          setData((prevData) => ({
                            ...prevData,
                            language_ielts_general_test_date: '',
                          }))
                        }
                      }}
                      error={errors.language_ielts_general_test_date}
                    />
                  </HStack>
                  <HStack>
                    <XNumberInput
                      type={'number'}
                      label={'Overall Score'}
                      precision={1}
                      error={errors.language_ielts_general_overall_score}
                      defaultValue={data.language_ielts_general_overall_score}
                      onChange={(value) => {
                        if (value !== null) {
                          setData((prevData) => ({
                            ...prevData,
                            language_ielts_general_overall_score: value,
                          }))
                        }
                      }}
                    />
                  </HStack>
                </HStack>
                <HStack spacing="12px" alignItems="flex-start">
                  <HStack>
                    <XNumberInput
                      type={'number'}
                      label={'Listening Score'}
                      precision={1}
                      error={errors.language_ielts_general_listening_score}
                      defaultValue={data.language_ielts_general_listening_score}
                      onChange={(value) => {
                        if (value !== null) {
                          setData((prevData) => ({
                            ...prevData,
                            language_ielts_general_listening_score: value,
                          }))
                        }
                      }}
                    />
                  </HStack>
                  <HStack>
                    <XNumberInput
                      type={'number'}
                      label={'Reading Score'}
                      precision={1}
                      error={errors.language_ielts_general_reading_score}
                      defaultValue={data.language_ielts_general_reading_score}
                      onChange={(value) => {
                        if (value !== null) {
                          setData((prevData) => ({
                            ...prevData,
                            language_ielts_general_reading_score: value,
                          }))
                        }
                      }}
                    />
                  </HStack>
                </HStack>
                <HStack spacing="12px" alignItems="flex-start">
                  <HStack>
                    <XNumberInput
                      type={'number'}
                      label={'Writing Score'}
                      precision={1}
                      error={errors.language_ielts_general_writing_score}
                      defaultValue={data.language_ielts_general_writing_score}
                      onChange={(value) => {
                        if (value !== null) {
                          setData((prevData) => ({
                            ...prevData,
                            language_ielts_general_writing_score: value,
                          }))
                        }
                      }}
                    />
                  </HStack>
                  <HStack>
                    <XNumberInput
                      type={'number'}
                      label={'Speaking Score'}
                      precision={1}
                      error={errors.language_ielts_general_speaking_score}
                      defaultValue={data.language_ielts_general_speaking_score}
                      onChange={(value) => {
                        if (value !== null) {
                          setData((prevData) => ({
                            ...prevData,
                            language_ielts_general_speaking_score: value,
                          }))
                        }
                      }}
                    />
                  </HStack>
                </HStack>
              </VStack>
            )}
            {languageTab === 'PTE Academic' && (
              <VStack spacing="12px" style={{ minHeight: '250px' }}>
                <HStack spacing="12px" alignItems="flex-start">
                  <HStack>
                    <XDatePicker
                      clearable
                      dateParser={(dateString) => {
                        return moment(dateString, 'DD/MM/YYYY').toDate()
                      }}
                      value={
                        data.language_pte_academic_test_date
                          ? moment(data.language_pte_academic_test_date as string, 'YYYY-MM-DD').toDate()
                          : null
                      }
                      width="100%"
                      placeholder="DD/MM/YYYY"
                      valueFormat={'DD/MM/YYYY'}
                      label={'Test Date'}
                      onChange={(value) => {
                        if (value) {
                          setData((prevData) => ({
                            ...prevData,
                            language_pte_academic_test_date: moment(value).format('YYYY-MM-DD'),
                          }))
                        } else {
                          setData((prevData) => ({
                            ...prevData,
                            language_pte_academic_test_date: '',
                          }))
                        }
                      }}
                      error={errors.language_pte_academic_test_date}
                    />
                  </HStack>
                  <HStack>
                    <XNumberInput
                      type={'number'}
                      label={'Overall Score'}
                      precision={1}
                      error={errors.language_pte_academic_overall_score}
                      defaultValue={data.language_pte_academic_overall_score}
                      onChange={(value) => {
                        if (value !== null) {
                          setData((prevData) => ({
                            ...prevData,
                            language_pte_academic_overall_score: value,
                          }))
                        }
                      }}
                    />
                  </HStack>
                </HStack>
                <HStack spacing="12px" alignItems="flex-start">
                  <HStack>
                    <XNumberInput
                      type={'number'}
                      label={'Listening Score'}
                      precision={1}
                      error={errors.language_pte_academic_listening_score}
                      defaultValue={data.language_pte_academic_listening_score}
                      onChange={(value) => {
                        if (value !== null) {
                          setData((prevData) => ({
                            ...prevData,
                            language_pte_academic_listening_score: value,
                          }))
                        }
                      }}
                    />
                  </HStack>
                  <HStack>
                    <XNumberInput
                      type={'number'}
                      label={'Reading Score'}
                      precision={1}
                      error={errors.language_pte_academic_reading_score}
                      defaultValue={data.language_pte_academic_reading_score}
                      onChange={(value) => {
                        if (value !== null) {
                          setData((prevData) => ({
                            ...prevData,
                            language_pte_academic_reading_score: value,
                          }))
                        }
                      }}
                    />
                  </HStack>
                </HStack>
                <HStack spacing="12px" alignItems="flex-start">
                  <HStack>
                    <XNumberInput
                      type={'number'}
                      label={'Writing Score'}
                      precision={1}
                      error={errors.language_pte_academic_writing_score}
                      defaultValue={data.language_pte_academic_writing_score}
                      onChange={(value) => {
                        if (value !== null) {
                          setData((prevData) => ({
                            ...prevData,
                            language_pte_academic_writing_score: value,
                          }))
                        }
                      }}
                    />
                  </HStack>
                  <HStack>
                    <XNumberInput
                      type={'number'}
                      label={'Speaking Score'}
                      precision={1}
                      error={errors.language_pte_academic_speaking_score}
                      defaultValue={data.language_pte_academic_speaking_score}
                      onChange={(value) => {
                        if (value !== null) {
                          setData((prevData) => ({
                            ...prevData,
                            language_pte_academic_speaking_score: value,
                          }))
                        }
                      }}
                    />
                  </HStack>
                </HStack>
              </VStack>
            )}
            {languageTab === 'PTE General' && (
              <VStack spacing="12px" style={{ minHeight: '250px' }}>
                <HStack spacing="12px" alignItems="flex-start">
                  <HStack>
                    <XDatePicker
                      clearable
                      dateParser={(dateString) => {
                        return moment(dateString, 'DD/MM/YYYY').toDate()
                      }}
                      value={
                        data.language_pte_general_test_date
                          ? moment(data.language_pte_general_test_date as string, 'YYYY-MM-DD').toDate()
                          : null
                      }
                      width="100%"
                      placeholder="DD/MM/YYYY"
                      valueFormat={'DD/MM/YYYY'}
                      label={'Test Date'}
                      onChange={(value) => {
                        if (value) {
                          setData((prevData) => ({
                            ...prevData,
                            language_pte_general_test_date: moment(value).format('YYYY-MM-DD'),
                          }))
                        } else {
                          setData((prevData) => ({
                            ...prevData,
                            language_pte_general_test_date: '',
                          }))
                        }
                      }}
                      error={errors.language_pte_general_test_date}
                    />
                  </HStack>
                  <HStack>
                    <XNumberInput
                      type={'number'}
                      label={'Overall Score'}
                      precision={1}
                      error={errors.language_pte_general_overall_score}
                      defaultValue={data.language_pte_general_overall_score}
                      onChange={(value) => {
                        if (value !== null) {
                          setData((prevData) => ({
                            ...prevData,
                            language_pte_general_overall_score: value,
                          }))
                        }
                      }}
                    />
                  </HStack>
                </HStack>
                <HStack spacing="12px" alignItems="flex-start">
                  <HStack>
                    <XNumberInput
                      type={'number'}
                      label={'Listening Score'}
                      precision={1}
                      error={errors.language_pte_general_listening_score}
                      defaultValue={data.language_pte_general_listening_score}
                      onChange={(value) => {
                        if (value !== null) {
                          setData((prevData) => ({
                            ...prevData,
                            language_pte_general_listening_score: value,
                          }))
                        }
                      }}
                    />
                  </HStack>
                  <HStack>
                    <XNumberInput
                      type={'number'}
                      label={'Reading Score'}
                      precision={1}
                      error={errors.language_pte_general_reading_score}
                      defaultValue={data.language_pte_general_reading_score}
                      onChange={(value) => {
                        if (value !== null) {
                          setData((prevData) => ({
                            ...prevData,
                            language_pte_general_reading_score: value,
                          }))
                        }
                      }}
                    />
                  </HStack>
                </HStack>
                <HStack spacing="12px" alignItems="flex-start">
                  <HStack>
                    <XNumberInput
                      type={'number'}
                      label={'Writing Score'}
                      precision={1}
                      error={errors.language_pte_general_writing_score}
                      defaultValue={data.language_pte_general_writing_score}
                      onChange={(value) => {
                        if (value !== null) {
                          setData((prevData) => ({
                            ...prevData,
                            language_pte_general_writing_score: value,
                          }))
                        }
                      }}
                    />
                  </HStack>
                  <HStack>
                    <XNumberInput
                      type={'number'}
                      label={'Speaking Score'}
                      precision={1}
                      error={errors.language_pte_general_speaking_score}
                      defaultValue={data.language_pte_general_speaking_score}
                      onChange={(value) => {
                        if (value !== null) {
                          setData((prevData) => ({
                            ...prevData,
                            language_pte_general_speaking_score: value,
                          }))
                        }
                      }}
                    />
                  </HStack>
                </HStack>
              </VStack>
            )}
            {languageTab === 'TOEFL Academic' && (
              <VStack spacing="12px" style={{ minHeight: '250px' }}>
                <HStack spacing="12px" alignItems="flex-start">
                  <HStack>
                    <XDatePicker
                      clearable
                      dateParser={(dateString) => {
                        return moment(dateString, 'DD/MM/YYYY').toDate()
                      }}
                      value={
                        data.language_toefl_academic_test_date
                          ? moment(data.language_toefl_academic_test_date as string, 'YYYY-MM-DD').toDate()
                          : null
                      }
                      width="100%"
                      placeholder="DD/MM/YYYY"
                      valueFormat={'DD/MM/YYYY'}
                      label={'Test Date'}
                      onChange={(value) => {
                        if (value) {
                          setData((prevData) => ({
                            ...prevData,
                            language_toefl_academic_test_date: moment(value).format('YYYY-MM-DD'),
                          }))
                        } else {
                          setData((prevData) => ({
                            ...prevData,
                            language_toefl_academic_test_date: '',
                          }))
                        }
                      }}
                      error={errors.language_toefl_academic_test_date}
                    />
                  </HStack>
                  <HStack>
                    <XNumberInput
                      type={'number'}
                      label={'Overall Score'}
                      precision={1}
                      error={errors.language_toefl_academic_overall_score}
                      defaultValue={data.language_toefl_academic_overall_score}
                      onChange={(value) => {
                        if (value !== null) {
                          setData((prevData) => ({
                            ...prevData,
                            language_toefl_academic_overall_score: value,
                          }))
                        }
                      }}
                    />
                  </HStack>
                </HStack>
                <HStack spacing="12px" alignItems="flex-start">
                  <HStack>
                    <XNumberInput
                      type={'number'}
                      label={'Listening Score'}
                      precision={1}
                      error={errors.language_toefl_academic_listening_score}
                      defaultValue={data.language_toefl_academic_listening_score}
                      onChange={(value) => {
                        if (value !== null) {
                          setData((prevData) => ({
                            ...prevData,
                            language_toefl_academic_listening_score: value,
                          }))
                        }
                      }}
                    />
                  </HStack>
                  <HStack>
                    <XNumberInput
                      type={'number'}
                      label={'Reading Score'}
                      precision={1}
                      error={errors.language_toefl_academic_reading_score}
                      defaultValue={data.language_toefl_academic_reading_score}
                      onChange={(value) => {
                        if (value !== null) {
                          setData((prevData) => ({
                            ...prevData,
                            language_toefl_academic_reading_score: value,
                          }))
                        }
                      }}
                    />
                  </HStack>
                </HStack>
                <HStack spacing="12px" alignItems="flex-start">
                  <HStack>
                    <XNumberInput
                      type={'number'}
                      label={'Writing Score'}
                      precision={1}
                      error={errors.language_toefl_academic_writing_score}
                      defaultValue={data.language_toefl_academic_writing_score}
                      onChange={(value) => {
                        if (value !== null) {
                          setData((prevData) => ({
                            ...prevData,
                            language_toefl_academic_writing_score: value,
                          }))
                        }
                      }}
                    />
                  </HStack>
                  <HStack>
                    <XNumberInput
                      type={'number'}
                      label={'Speaking Score'}
                      precision={1}
                      error={errors.language_toefl_academic_speaking_score}
                      defaultValue={data.language_toefl_academic_speaking_score}
                      onChange={(value) => {
                        if (value !== null) {
                          setData((prevData) => ({
                            ...prevData,
                            language_toefl_academic_speaking_score: value,
                          }))
                        }
                      }}
                    />
                  </HStack>
                </HStack>
              </VStack>
            )}
            {languageTab === 'TOEFL Home Edition' && (
              <VStack spacing="12px" style={{ minHeight: '250px' }}>
                <HStack spacing="12px" alignItems="flex-start">
                  <HStack>
                    <XDatePicker
                      clearable
                      dateParser={(dateString) => {
                        return moment(dateString, 'DD/MM/YYYY').toDate()
                      }}
                      value={
                        data.language_toefl_home_edition_test_date
                          ? moment(data.language_toefl_home_edition_test_date as string, 'YYYY-MM-DD').toDate()
                          : null
                      }
                      width="100%"
                      placeholder="DD/MM/YYYY"
                      valueFormat={'DD/MM/YYYY'}
                      label={'Test Date'}
                      onChange={(value) => {
                        if (value) {
                          setData((prevData) => ({
                            ...prevData,
                            language_toefl_home_edition_test_date: moment(value).format('YYYY-MM-DD'),
                          }))
                        } else {
                          setData((prevData) => ({
                            ...prevData,
                            language_toefl_home_edition_test_date: '',
                          }))
                        }
                      }}
                      error={errors.language_toefl_home_edition_test_date}
                    />
                  </HStack>
                  <HStack>
                    <XNumberInput
                      type={'number'}
                      label={'Overall Score'}
                      precision={1}
                      error={errors.language_toefl_home_edition_overall_score}
                      defaultValue={data.language_toefl_home_edition_overall_score}
                      onChange={(value) => {
                        if (value !== null) {
                          setData((prevData) => ({
                            ...prevData,
                            language_toefl_home_edition_overall_score: value,
                          }))
                        }
                      }}
                    />
                  </HStack>
                </HStack>
                <HStack spacing="12px" alignItems="flex-start">
                  <HStack>
                    <XNumberInput
                      type={'number'}
                      label={'Listening Score'}
                      precision={1}
                      error={errors.language_toefl_home_edition_listening_score}
                      defaultValue={data.language_toefl_home_edition_listening_score}
                      onChange={(value) => {
                        if (value !== null) {
                          setData((prevData) => ({
                            ...prevData,
                            language_toefl_home_edition_listening_score: value,
                          }))
                        }
                      }}
                    />
                  </HStack>
                  <HStack>
                    <XNumberInput
                      type={'number'}
                      label={'Reading Score'}
                      precision={1}
                      error={errors.language_toefl_home_edition_reading_score}
                      defaultValue={data.language_toefl_home_edition_reading_score}
                      onChange={(value) => {
                        if (value !== null) {
                          setData((prevData) => ({
                            ...prevData,
                            language_toefl_home_edition_reading_score: value,
                          }))
                        }
                      }}
                    />
                  </HStack>
                </HStack>
                <HStack spacing="12px" alignItems="flex-start">
                  <HStack>
                    <XNumberInput
                      type={'number'}
                      label={'Writing Score'}
                      precision={1}
                      error={errors.language_toefl_home_edition_writing_score}
                      defaultValue={data.language_toefl_home_edition_writing_score}
                      onChange={(value) => {
                        if (value !== null) {
                          setData((prevData) => ({
                            ...prevData,
                            language_toefl_home_edition_writing_score: value,
                          }))
                        }
                      }}
                    />
                  </HStack>
                  <HStack>
                    <XNumberInput
                      type={'number'}
                      label={'Speaking Score'}
                      precision={1}
                      error={errors.language_toefl_home_edition_speaking_score}
                      defaultValue={data.language_toefl_home_edition_speaking_score}
                      onChange={(value) => {
                        if (value !== null) {
                          setData((prevData) => ({
                            ...prevData,
                            language_toefl_home_edition_speaking_score: value,
                          }))
                        }
                      }}
                    />
                  </HStack>
                </HStack>
              </VStack>
            )}
            {languageTab === 'Duolingo' && (
              <VStack spacing="12px" style={{ minHeight: '250px' }}>
                <HStack spacing="12px" alignItems="flex-start">
                  <HStack>
                    <XDatePicker
                      clearable
                      dateParser={(dateString) => {
                        return moment(dateString, 'DD/MM/YYYY').toDate()
                      }}
                      value={
                        data.language_duolingo_test_date
                          ? moment(data.language_duolingo_test_date as string, 'YYYY-MM-DD').toDate()
                          : null
                      }
                      width="100%"
                      placeholder="DD/MM/YYYY"
                      valueFormat={'DD/MM/YYYY'}
                      label={'Test Date'}
                      onChange={(value) => {
                        if (value) {
                          setData((prevData) => ({
                            ...prevData,
                            language_duolingo_test_date: moment(value).format('YYYY-MM-DD'),
                          }))
                        } else {
                          setData((prevData) => ({
                            ...prevData,
                            language_duolingo_test_date: '',
                          }))
                        }
                      }}
                      error={errors.language_duolingo_test_date}
                    />
                  </HStack>
                  <HStack>
                    <XNumberInput
                      type={'number'}
                      label={'Overall Score'}
                      precision={1}
                      error={errors.language_duolingo_overall_score}
                      defaultValue={data.language_duolingo_overall_score}
                      onChange={(value) => {
                        if (value !== null) {
                          setData((prevData) => ({
                            ...prevData,
                            language_duolingo_overall_score: value,
                          }))
                        }
                      }}
                    />
                  </HStack>
                </HStack>
                <HStack spacing="12px" alignItems="flex-start">
                  <HStack>
                    <XNumberInput
                      type={'number'}
                      label={'Literacy Score'}
                      precision={1}
                      error={errors.language_duolingo_literacy_score}
                      defaultValue={data.language_duolingo_literacy_score}
                      onChange={(value) => {
                        if (value !== null) {
                          setData((prevData) => ({
                            ...prevData,
                            language_duolingo_literacy_score: value,
                          }))
                        }
                      }}
                    />
                  </HStack>
                  <HStack>
                    <XNumberInput
                      type={'number'}
                      label={'Comprehension Score'}
                      precision={1}
                      error={errors.language_duolingo_comprehension_score}
                      defaultValue={data.language_duolingo_comprehension_score}
                      onChange={(value) => {
                        if (value !== null) {
                          setData((prevData) => ({
                            ...prevData,
                            language_duolingo_comprehension_score: value,
                          }))
                        }
                      }}
                    />
                  </HStack>
                </HStack>
                <HStack spacing="12px" alignItems="flex-start">
                  <HStack>
                    <XNumberInput
                      type={'number'}
                      label={'Conversation Score'}
                      precision={1}
                      error={errors.language_duolingo_conversation_score}
                      defaultValue={data.language_duolingo_conversation_score}
                      onChange={(value) => {
                        if (value !== null) {
                          setData((prevData) => ({
                            ...prevData,
                            language_duolingo_conversation_score: value,
                          }))
                        }
                      }}
                    />
                  </HStack>
                  <HStack>
                    <XNumberInput
                      type={'number'}
                      label={'Production Score'}
                      precision={1}
                      error={errors.language_duolingo_production_score}
                      defaultValue={data.language_duolingo_production_score}
                      onChange={(value) => {
                        if (value !== null) {
                          setData((prevData) => ({
                            ...prevData,
                            language_duolingo_production_score: value,
                          }))
                        }
                      }}
                    />
                  </HStack>
                </HStack>
              </VStack>
            )}
          </VStack>
        )}
      </VStack>
      <HStack>
        <Divider variant={'dotted'}></Divider>
      </HStack>
      <HStack spacing={'16px'} justifyContent="flex-end">
        <Button
          label={'Cancel'}
          variant={'secondary'}
          onClick={() => {
            setLoading(false)
            dispatch(updateShowAddQuickLeadModal(false))
            reset()
          }}
        ></Button>
        <Button
          label={'Create'}
          onClick={() => {
            setLoading(true)
            post(route('ielts-leads.quick-create'), {
              preserveState: true,
              preserveScroll: true,
              onFinish: () => {
                setLoading(false)
              },
            })
          }}
        ></Button>
      </HStack>
    </VStack>
  )
}

export default CreateQuickIeltsLead
